import { Link } from "react-router-dom";
import "./SignUp.css";
import logo from "./logo.png";

function SignUp() {
  return (
    <div className="signup-container">
      <img className="skulpt-logo" src={logo} alt="Skul:pt logo" />
      <h1 className="white-text small-text-h1">ENABLING THE FIRST AI GYMS</h1>
      <h2 className="red-text small-text-h2">
        WHERE THE GYM IS YOUR
        <br /> PERSONAL TRAINER
      </h2>
      <Link to="/signup">
        <button className="signup-button">GET EARLY ACCESS</button>
      </Link>
    </div>
  );
}

export default SignUp;
