import "./Photo.css";
import photo from "./landing_squat.png";

function Photo() {
  return (
    <div className="photo-container">
      <img
        className="squat-photo"
        src={photo}
        alt="squatting woman's form being analysed by camera"
      />
    </div>
  );
}

export default Photo;
