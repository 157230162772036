import Photo from "../Photo/Photo";
import SignUp from "../SignUp/SignUp";
import "./HomePage.css";

function HomePage() {
  return (
    <div className="App">
      <SignUp />
      <Photo />
    </div>
  );
}

export default HomePage;
