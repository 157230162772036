import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./SignUpPage.css";

function SignUpPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gymMember, setGymMember] = useState("");
  const [frequency, setFrequency] = useState("");

  const form = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs.sendForm(
      "service_wr2l3v8",
      "template_48x4yeg",
      form.current,
      "PxXElfb83R8jwCj_F"
    );
  };

  return (
    <div className="signup-page">
      <form ref={form} onSubmit={handleSubmit}>
        <div>
          <label className="white-text small-text" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label className="white-text small-text" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label className="white-text small-text" htmlFor="gymMember">
            Where are you a gym member at?
          </label>
          <input
            type="text"
            id="gymMember"
            name="gymMember"
            value={gymMember}
            onChange={(e) => setGymMember(e.target.value)}
          />
        </div>
        <div>
          <label className="white-text small-text" htmlFor="frequency">
            How often do you go to the gym?
          </label>
          <input
            type="text"
            id="frequency"
            name="frequency"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
          />
        </div>
        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default SignUpPage;
